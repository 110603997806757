import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import zh from 'ant-design-vue/es/locale/zh_CN';
import 'dayjs/locale/zh-cn';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_a_config_provider = _resolveComponent("a-config-provider");
      return _openBlock(), _createBlock(_component_a_config_provider, {
        locale: _unref(zh)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};